<template>

	<div class="section offers" v-if="contentDataSet.data !== null && contentDataSet.data.length>0">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<h5 class="post-heading">ANGEBOTE</h5>
				</div>
				<div class="scroll-container">
					<angebot-card v-for="(item, index) in contentDataSet.data" :key="index" :item="item" class="angebot-card"/>
				</div>
			</div>
			<pagination class="pagination-style" v-if="contentDataSet.total>8" :page-count="contentDataSet.meta.last_page" :click-handler="changePage" containerClass="pagination" prev-text="&laquo" next-text="&raquo"/>
			</div>
		</div>

	</div>
</template>
<script>
	import Pagination from '@/components/controls/Pagination';
	import AngebotCard from '@/components/cards/Angebot.vue';
	import { getInstitutionContents } from '@/api/institution'
	import { showLoader, hideLoader } from '@/utils/helpers';

	export default {
		name: "InstitutionAngebotBlock",
		components: {
			AngebotCard,
			Pagination
		},
		props: {
			content: {
				type: Object,
				required: true
			},
		},
		data(){
			return{
				loader: null,
				contentDataSet: {
					total: 0,
					data: null,
					meta:null,
					query: {
						page:1,
						limit: 8,
						keyword: '',
						selectedAppId: this.$appId,
						selectedContentTypeId: this.$offerId,
						view_status: 1,
						sort: {
							prop: '',
							order: 'asc'
						},
					},
				},
			}
		},
		created() {
			this.getInstitutionContents();
		},
		methods: {
			showLoader,
			hideLoader,
			changePage(page){
				this.contentDataSet.query.page = page;
				this.getInstitutionContents();
			},
			getInstitutionContents() {
				this.loader = this.showLoader(this.loader);
				const { limit, page } = this.contentDataSet.query;
				getInstitutionContents(this.content.institution_id,this.contentDataSet.query).then(response => {
					var data = response.data;
					var meta = response.meta;
					this.contentDataSet.data = data;
					this.contentDataSet.data.forEach((element, index) => {
						element['index'] = (page - 1) * limit + index + 1;
					});
					this.contentDataSet.meta = meta;
					this.contentDataSet.total = meta.total;
				}).catch(error => {
					console.log(error);
				}).finally(() => {
					this.loader = this.hideLoader(this.loader);
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/scss/_variables.scss';

	.offers-row{
		padding-left: 15px;
	}

	.angebot-card {
		min-width: 280px;
		max-width: 374px;

		@media (max-width: 1197px) {
			min-width: 280px;
			max-width: 300px;
		}
	}

	.scroll-container {
		display: flex !important;
		flex-direction: row;
		flex-wrap: wrap;
		padding-left: 15px;
		padding-right: 15px;

		&::-webkit-scrollbar {
			height: 0;
		}

		@media (max-width: 992px) {
			flex-wrap: nowrap;
			padding-left: 15px;
			overflow-x: auto;
		}
	}

</style>
